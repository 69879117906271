import React from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useRecoilValue} from 'recoil';

import Modal from '../../../layouts/Modal';
import styles from './styles.module.scss';
import {getBrandInfo} from '../../../services/brands';
import {user} from '../../../store/user';

interface Props {
  isOpen: boolean;
  setIsOpen: (is: boolean) => void;
  parentId: number;
  separators: {
    id: number;
    area: string;
  }[];
  reload: (_brandInfo: BrandListItemType) => void;
}

export default function SeparatorSelector({
  isOpen,
  setIsOpen,
  separators,
  parentId,
  reload,
}: Props) {
  const param = useParams();
  const location = useLocation();
  const navigator = useNavigate();
  const accessToken = useRecoilValue(user);

  const toggle = async (id: number) => {
    const res = await getBrandInfo(accessToken, parentId);
    const child = res.data.brands.find((o: BrandListItemType) => o.id === id);
    reload(child);
    setIsOpen(false);
    navigator(`/brand/${parentId}/${id}`, {
      replace: true,
      state: {
        ...location.state,
        prev: location.pathname.replace('/', ''),
      },
    });
  };
  return (
    <Modal.BottomSheet isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className={styles.sheet}>
        {separators.map(o => {
          return (
            <div
              className={`${styles.item} ${
                param.placeId && Number(param.placeId) === o.id
                  ? styles.active
                  : ''
              }`}
              onClick={() => toggle(o.id)}
              key={o.id}>
              {o.area}
            </div>
          );
        })}
      </div>
    </Modal.BottomSheet>
  );
}

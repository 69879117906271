import React, {useEffect, useMemo, useRef, useState} from 'react';
import {Capacitor} from '@capacitor/core';
import {useRecoilState, useSetRecoilState} from 'recoil';

import styles from '../styles.module.scss';
import Layout from '../../../layouts';
import {isMobile} from '../../../utils';
import {
  BrandMapLocation,
  BrandMapUrl,
  ToastMessage,
  isOpenToast,
} from '../../../store';
import useNative from '../../../hooks/useNative';
import useLogger, {Action} from '../../../hooks/useLogger';

interface Props {
  placeInfo: PlaceInfoProps;
}

export default function PlaceInfo({placeInfo}: Props) {
  const sender = useLogger();
  const {Echo} = useNative();
  const mapElement = useRef<HTMLDivElement>(null);
  const routeElement = useRef<HTMLAnchorElement>(null);
  const {naver} = window as any;
  const [userGeoLocation, setUserGeoLocation] = useState<number[]>([]);
  const [locationInfo, setLocationInfo] =
    useRecoilState<number[]>(BrandMapLocation);
  const [mapUrl, setMapUrl] = useRecoilState<string>(BrandMapUrl);
  const setIsOpenToast = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const isWeb = useMemo(() => {
    return Capacitor.getPlatform() === 'web' && !isMobile();
  }, []);
  const initMap = () => {
    if (!mapElement.current || !window) {
      return;
    }
    if (placeInfo && mapElement.current && naver.maps) {
      naver.maps.Service.geocode(
        {
          address: placeInfo.address,
        },
        (status: any, response: NaverMapGeoCodeResponse) => {
          const {x, y} = response.result.items[1].point;
          setLocationInfo(() => {
            return [Number(x), Number(y)];
          });
        },
      );
    }
  };
  const copy = async (address: string) => {
    window.navigator.clipboard.writeText(`${address}`);
    setToastMessage('복사되었습니다.');
    setIsOpenToast(true);
  };
  const onGeoloationSucess = (position: any) => {
    if (position && position.coords) {
      setUserGeoLocation(() => {
        return [position.coords.longitude, position.coords.latitude];
      });
    }
  };
  const onGeolocationError = () => {
    setUserGeoLocation([-1, -1]);
  };
  const openNaverMapAppOnMW = () => {
    location.href = `nmap://route/car?dlat=${locationInfo[0]}&dlng=${locationInfo[1]}&dname=${placeInfo.name}&appname=knewnew`;
  };
  const openLink = async (link: any) => {
    if (Capacitor.getPlatform() === 'web') {
      window.open(link, '_blank');
    } else {
      // await Browser.open({url: link});
      await Echo.echo({
        value: `instagram*${link}`,
      });
    }
  };
  const moveToNaverMap = () => {
    if (Capacitor.getPlatform() === 'web') {
      openNaverMapAppOnMW();
    } else {
      Echo.echo({
        value: `map*${locationInfo[1]}*${locationInfo[0]}*${placeInfo.name}`,
      });
    }
  };
  const makeMapUrl = () => {
    let mobileUrl = '';
    let pcUrl = '';
    if (userGeoLocation.length > 0 && userGeoLocation[0] > -1) {
      mobileUrl = `http://m.map.naver.com/route.nhn?menu=route&sname=${'내위치'}&sx=${
        userGeoLocation[0]
      }&sy=${userGeoLocation[1]}&ename='${placeInfo.name.replaceAll(
        ' ',
        '',
      )}'&ex=${locationInfo[0]}&ey=${locationInfo[1]}&pathType=0&showMap=true`;
      pcUrl = `http://map.naver.com/index.nhn?slng=${userGeoLocation[0]}&slat=${
        userGeoLocation[1]
      }&stext='내위치'&elng=${locationInfo[0]}&elat=${
        locationInfo[1]
      }&etext='${placeInfo.name.replaceAll(' ', '')}'&menu=route&pathType=1`;
    } else {
      mobileUrl = `http://m.map.naver.com/route.nhn?menu=route&ename='${placeInfo.name.replaceAll(
        ' ',
        '',
      )}'&ex=${locationInfo[0]}&ey=${locationInfo[1]}&pathType=0&showMap=true`;
      pcUrl = `http://map.naver.com/index.nhn?elng=${locationInfo[0]}&elat=${
        locationInfo[1]
      }&etext='${placeInfo.name.replaceAll(' ', '')}'&menu=route&pathType=1`;
    }
    setMapUrl(isMobile() ? mobileUrl : pcUrl);
  };
  useEffect(() => {
    if (placeInfo && mapElement.current) {
      initMap();
    }
    // 웹일경우 geoloation api로 현재위치 받아오기
    if (Capacitor.getPlatform() === 'web') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          onGeoloationSucess,
          onGeolocationError,
        );
      }
    }
  }, [placeInfo, mapElement]);
  useEffect(() => {
    if (locationInfo.length === 2) {
      let map: any = null;
      const location = new naver.maps.LatLng(locationInfo[1], locationInfo[0]);
      const mapOptions = {
        center: location,
        zoom: 18,
        zoomControl: false,
      };
      map = new naver.maps.Map('map', mapOptions);
      new naver.maps.Marker({
        position: location,
        map,
      });
      // 위치 정보 이용 동의
      // if (userGeoLocation.length === 2 && userGeoLocation[0] > -1) {
      //   makeMapUrl();
      // } else {
      //   setMapUrl(
      //     `https://map.naver.com/p/search/${encodeURIComponent(
      //       placeInfo.name,
      //     )}?lat=${locationInfo[1]}&lng=${locationInfo[0]}`,
      //   );
      // }

      makeMapUrl();
    }
  }, [locationInfo, userGeoLocation]);
  return (
    <Layout.BrandCardView title={'상세정보'}>
      <div className={styles.placeInfoWrapper}>
        <div ref={mapElement} className={styles.mapArea} id="map"></div>
        <div
          className={`${styles.placeInfo} ${styles.address}`}
          style={{marginTop: '30px'}}>
          <span className={styles.icon}></span>
          <span className={styles.addressTxt}>{placeInfo.address}</span>
          <span className={styles.copy} onClick={() => copy(placeInfo.address)}>
            복사
          </span>
        </div>
        <div className={`${styles.placeInfo} ${styles.schedule}`}>
          <span className={styles.icon}></span>
          <div className={styles.scheduleInfo}>
            {placeInfo.openingHours.map(hour => {
              return <span key={hour}>{hour}</span>;
            })}
          </div>
        </div>
        <div className={`${styles.placeInfo} ${styles.call}`}>
          <span className={styles.icon}></span>
          <span>{placeInfo.brandTel}</span>
          <span
            className={styles.copy}
            onClick={() => copy(placeInfo.brandTel)}>
            복사
          </span>
        </div>
        <div
          className={`${styles.placeInfo} ${styles.links}`}
          style={{marginBottom: '30px'}}>
          <span className={styles.icon}></span>
          {placeInfo.brandLink && (
            <span
              className={styles.link}
              onClick={() => openLink(placeInfo.brandLink)}>
              인스타그램
            </span>
          )}
          {placeInfo.reserveLink && (
            <span
              className={styles.link}
              onClick={() => {
                sender({
                  _msg: 'bookingSBtn',
                  _screen: window.location.pathname,
                  _action: Action.CLICK,
                });
                openLink(placeInfo.reserveLink);
              }}>
              예약사이트
            </span>
          )}
        </div>
        <div style={{padding: '0 20px 60px 20px'}}>
          {isWeb ? (
            <a
              ref={routeElement}
              className={styles.findMapBtn}
              href={`${mapUrl}`}
              target="_blank"
              rel="noreferrer">
              <span className={styles.txt}>매장 길찾기</span>
              <span className={styles.icon}></span>
            </a>
          ) : (
            <button
              className={styles.findMapBtn}
              onClick={() => moveToNaverMap()}>
              <span className={styles.txt}>매장 길찾기</span>
              <span className={styles.icon}></span>
            </button>
          )}
        </div>
      </div>
    </Layout.BrandCardView>
  );
}

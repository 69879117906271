import React, {useEffect, useMemo, useRef, useState} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
// eslint-disable-next-line import/order
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil';

// eslint-disable-next-line import/order
// eslint-disable-next-line import/order
import {Capacitor} from '@capacitor/core';
// eslint-disable-next-line import/order
import {useNavigate} from 'react-router-dom';
// eslint-disable-next-line import/order
// eslint-disable-next-line import/order
import Layout from '@/layouts';

// eslint-disable-next-line import/order

// eslint-disable-next-line import/order
import {
  AreaBrandListArgs,
  BrandAddr2,
  BrandAddr3,
  BrandAreaListPage,
  BrandListItems,
  CurrentOffsetForBrandList,
  IsOnlyBenefit,
  SelectedCategory,
} from '@/store/brand';

// eslint-disable-next-line import/order
import {getBrands} from '@/services/brands';

// eslint-disable-next-line import/order
import Header from '@/layouts/Header';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// eslint-disable-next-line import/order
import {
  AddressList,
  isOpenAddressSelector,
  isOpenToast,
  ToastMessage,
} from '@/store';

// eslint-disable-next-line import/order
import AddressSelector from '@/components/community/AddressSelector';

import {user} from '@/store/user';
import BrandSortBottomSheet from '@/components/brandSortBottomSheet';
import useGeoLocation from '@/hooks/useGeoLocation';
import BrandListItem from '@/components/brandListItem';
import GeolocationModal from '@/components/common/GeolocationModal';
import useNative from '@/hooks/useNative';
import LocationLoader from '@/components/common/Loader/LocationLoader';
import BrandLoginModal from '@/components/common/BrandLoginModal';
import {useScroll} from '@/hooks/useScroll';
import useLogger, {Action} from '@/hooks/useLogger';

import styles from './styles.module.scss';

export interface BrandListArguments {
  address3: number | undefined;
  offset: number;
  address2: number | undefined;
  limit: number;
  sort: 'newest';
  lon?: number | null | undefined;
  lat?: number | null | undefined;
  category?: string;
  filter?: 'product' | undefined;
}

export default function BrandList() {
  const {syncScroll, resetScroll, checkScrolled} = useScroll();
  const {
    isPossibleGeolocation: isActiveCurrentPosition,
    isGeolocationModalOpen,
    permissionState,
    setIsGeolocationModalOpen,
    openGeolocationChecker,
    setIsAreaSelected,
    getCurrentPosition,
  } = useGeoLocation();
  const {Echo} = useNative();
  const accessToken = useRecoilValue(user);
  const navigator = useNavigate();
  const realtimeChecker = useRef<HTMLInputElement | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [brandItems, setBrandItems] = useRecoilState(BrandListItems);
  const setIsOpen = useSetRecoilState(isOpenAddressSelector);
  const addressList = useRecoilValue(AddressList);
  const setIsOpenToastModal = useSetRecoilState(isOpenToast);
  const setToastMessage = useSetRecoilState(ToastMessage);
  const [isScrollend, setIsScrollend] = useState(false);
  const [maxCount, setMaxCount] = useState(200);
  const [isLoad, setIsLoad] = useState(false);
  const [isRefresh, setisRefresh] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenSortModal, setIsOpenSortModal] = useState(false);
  const [selectedCategory, setSelectedCategory] =
    useRecoilState(SelectedCategory);
  const setCurrentOffset = useSetRecoilState(CurrentOffsetForBrandList);
  const [isOnlyBenefit, setIsOnlyBenefit] = useRecoilState(IsOnlyBenefit);
  const [areas, setAreas] = useState<{area2: string; area3: string}>();
  const [addr2Code, setAddr2Code] = useRecoilState(BrandAddr2);
  const [addr3Code, setAddr3Code] = useRecoilState(BrandAddr3);
  const [page, setPage] = useRecoilState(BrandAreaListPage);
  const args: BrandListArguments = useRecoilValue(AreaBrandListArgs);
  const [totalPage, setTotalPage] = useState(0);
  const sender = useLogger();

  const loadLocationInfo = () => {
    getCurrentPosition && getCurrentPosition();
    resetScroll();
    window.scrollTo(0, 0);
    setisRefresh(true);
  };

  const calcedBrandItems = useMemo(() => {
    if (brandItems.length > 0 && !accessToken) {
      return brandItems.slice(0, 3);
    }
    return brandItems;
  }, [brandItems, accessToken]);

  const addr2 = useMemo(() => {
    if (!addr2Code) {
      return areas?.area2;
    }
    return addressList.find(o => o.id === addr2Code)?.address;
  }, [addr2Code, addressList, areas]);

  const addr3 = useMemo(() => {
    if (!addr2Code || areas?.area3) {
      return areas?.area3;
    }
    return addr3Code
      ? addressList
          .find(o => o.id === addr2Code)
          ?.child?.find(o => o.id === addr3Code)?.address
      : '전체';
  }, [addr3Code, addr2Code, addressList, areas]);

  const toggleSelectedCategory = (category: string) => {
    setIsLoad(false);
    resetScroll();
    setSelectedCategory(category);
    setCurrentOffset(0);
    scrollTo(0, 0);
  };

  const updateAddress = (address2: string, address3: string) => {
    const addr2 = addressList.find(o => o.address === address2);
    setIsLoad(false);
    setIsAreaSelected && setIsAreaSelected(true);
    setPage(0);
    setCurrentOffset(0);
    resetScroll();
    if (addr2) {
      const addr3 = addressList
        .find(o => o.address === address2)
        ?.child?.find(o => o.address === address3);
      if (addr3) {
        setAddr2Code(addr2.id);
        setAddr3Code(addr3.id);
        setAreas({
          area2: addr2.address,
          area3: addr3.address,
        });
      } else if (address3 === '전체') {
        setAddr2Code(addr2.id);
        setAddr3Code(undefined);
        setAreas({
          area2: addr2.address,
          area3: '전체',
        });
      }
    }
    scrollTo(0, 0);
  };

  const getData = async (pArgs: BrandListArguments) => {
    const _args = pArgs;
    if (!_args.address2) {
      return false;
    }
    const reqParam: BrandListParam = {
      limit: _args.limit,
      offset: _args.offset,
      address_2: _args.address2,
      address_3: _args.address3,
      sort: 'newest',
      category: _args.category,
      filter: _args.filter,
    };
    reqParam.limit = 50;
    const res = await getBrands(accessToken, reqParam);
    setBrandItems(
      _args.offset === 0
        ? res.data.results
        : [...brandItems, ...res.data.results],
    );
    setMaxCount(res.data.count);
    setTotalPage(Math.ceil(res.data.count / 50));
    setIsLoad(true);
    if (checkScrolled(window.location.pathname)) {
      syncScroll();
    } else {
      resetScroll();
    }
  };

  const getGeoLocationData = async (_args: BrandListArguments) => {
    if (!_args.lon) {
      return;
    }
    const reqParam: BrandListParam = {
      limit: _args.limit,
      offset: _args.offset,
      address_2: _args.address2,
      address_3: _args.address3,
      lat: _args.lat,
      lon: _args.lon,
      sort: 'newest',
      category: _args.category,
      filter: _args.filter,
    };
    const res = await getBrands(accessToken, reqParam);
    if (addressList) {
      const address2Obj = addressList.find(o => o.address === res.data.area2);
      setAddr2Code(address2Obj?.id || undefined);
      const addr3Info = address2Obj?.child?.find(
        o => o.address === res.data.area3,
      );
      setAddr3Code(addr3Info ? addr3Info.id : undefined);
      setAreas({
        area2: res.data.area2,
        area3: res.data.area3,
      });
    }
    setTotalPage(Math.ceil(res.data.count / 50));
    setBrandItems(
      _args.offset === 0
        ? res.data.results
        : [...brandItems, ...res.data.results],
    );
    if (_args.offset == 0) {
      sender({
        _msg: isRefresh ? 'brand_scan_refresh' : 'brand_scan',
        _screen: window.location.pathname,
        _action: Action.CLICK,
        _data: args,
      });
    }
    setMaxCount(res.data.count);
    setIsLoad(true);
    setisRefresh(false);
    if (checkScrolled(window.location.pathname)) {
      syncScroll();
    } else {
      resetScroll();
    }
  };

  const InfiniteList = () => {
    return (
      <InfiniteScroll
        pageStart={page}
        loadMore={(page: number) => {
          if (page < totalPage) {
            setIsLoad(false);
            setPage(page);
            setCurrentOffset(page * 50);
          }
        }}
        hasMore={brandItems.length < maxCount}>
        <div
          className={styles.brandList}
          style={{
            minHeight: `calc(100vh - 209px)`,
            paddingBottom: `60px`,
          }}>
          {brandItems &&
            brandItems.length > 0 &&
            calcedBrandItems.map((item, i) => {
              return (
                <BrandListItem
                  key={`brand_list_item_${item.id}`}
                  item={item}
                  itemStyle={{
                    margin: i === 0 ? '0 0 40px 0' : '40px 0',
                  }}
                  isCurrentLocation={isActiveCurrentPosition as boolean}
                />
              );
            })}
          {isLoad && brandItems && brandItems.length === 0 && (
            <div
              style={{
                minHeight: 'calc(100vh - 209px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '140%',
                letterSpacing: '-0.28px',
                color: '#78737D',
              }}>
              검색 결과가 없습니다.
            </div>
          )}
          {brandItems &&
            brandItems.length > 0 &&
            brandItems.length >= maxCount && (
              <div className={styles.othersAreaWrapper}>
                <div className={styles.mapIcon}></div>
                <button
                  className={styles.othersArea}
                  onClick={() =>
                    accessToken ? setIsOpen(true) : setIsOpenModal(true)
                  }>
                  다른 지역에서 찾기
                </button>
              </div>
            )}
        </div>
      </InfiniteScroll>
    );
  };
  const UnAuthList = () => {
    return (
      <div ref={containerRef}>
        <div
          ref={contentRef}
          className={styles.brandList}
          style={{
            minHeight: `calc(100vh - 209px)`,
            paddingBottom: `60px`,
          }}>
          {brandItems &&
            brandItems.length > 0 &&
            calcedBrandItems.map((item, i) => {
              return (
                <BrandListItem
                  key={`brand_list_item_${item.id}`}
                  item={item}
                  itemStyle={{
                    margin: i === 0 ? '0 0 40px 0' : '40px 0',
                  }}
                  isCurrentLocation={isActiveCurrentPosition as boolean}
                  isBlur={i === 2}
                  isScrollend={isScrollend}
                />
              );
            })}
          {isLoad && brandItems && brandItems.length === 0 && (
            <div
              style={{
                minHeight: 'calc(100vh - 209px)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                fontSize: '14px',
                fontWeight: '400',
                lineHeight: '140%',
                letterSpacing: '-0.28px',
                color: '#78737D',
              }}>
              검색 결과가 없습니다.
            </div>
          )}
        </div>
      </div>
    );
  };
  useEffect(() => {
    if (permissionState && permissionState.location !== 'granted') {
      if (Capacitor.getPlatform() !== 'web') {
        openGeolocationChecker && openGeolocationChecker();
      } else {
        setIsOpenToastModal(true);
        setToastMessage('브라우저 설정에서 위치정보 사용을 허용해주세요');
      }
    }
    scrollTo(0, 0);
  }, [permissionState]);

  useEffect(() => {
    if (!isLoad) {
      if (!isActiveCurrentPosition) {
        getData(args);
      } else {
        getGeoLocationData(args);
      }
    }
    if (isRefresh) {
      getGeoLocationData(args);
    }
  }, [args, isActiveCurrentPosition, isLoad, isRefresh]);

  useEffect(() => {
    const handleScroll = () => {
      if (contentRef.current) {
        const rect = contentRef.current.getBoundingClientRect();
        if (rect.bottom <= window.innerHeight) {
          console.log('컨텐츠 div의 스크롤이 완료되었습니다.');
          setIsScrollend(true);
        }
      }
    };
    if (!accessToken) {
      window.addEventListener('scroll', () => handleScroll());
    }
    return () => {
      if (!accessToken) {
        window.removeEventListener('scroll', handleScroll);
      }
    };
  }, [containerRef, contentRef]);

  // useEffect(() => {
  //   syncScroll();
  // }, []);

  return (
    <Layout.Main>
      <div className={styles.contents}>
        <Header
          title={
            <div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}>
                {isActiveCurrentPosition && (
                  <div
                    className={styles.currentPosWrapper}
                    onClick={() => {
                      loadLocationInfo();
                    }}>
                    <span className={styles.loop}></span>
                    <span className={styles.currentPosition}>현위치</span>
                  </div>
                )}
                <div
                  onClick={() =>
                    accessToken ? setIsOpen(true) : setIsOpenModal(true)
                  }>
                  <span className={styles.addr2} style={{marginRight: '5px'}}>
                    {addr2}
                  </span>
                  <span className={styles.addr3}>{addr3}</span>
                </div>
              </div>
            </div>
          }
          isCommunity
          isWhite
          isNoRing
          hasDropdown
          isShowSearch
          isRealtime={isActiveCurrentPosition}
        />
        {isLoad && (
          <div className={styles.benefits} style={{cursor: 'pointer'}}>
            <label className={styles.custom_checkbox}>
              <input
                type="checkbox"
                checked={isOnlyBenefit}
                ref={realtimeChecker}
                onChange={() => {
                  if (accessToken) {
                    setIsLoad(false);
                    setIsOnlyBenefit(!isOnlyBenefit);
                    setCurrentOffset(0);
                  } else {
                    setIsOpenModal(true);
                  }
                }}
              />
              <span className={styles.checkmark}></span>
            </label>
            <span
              onClick={() => {
                if (realtimeChecker.current) {
                  realtimeChecker.current.click();
                }
              }}>
              <strong>실시간 혜택</strong> 모아보기
            </span>
          </div>
        )}
        {!isLoad && <LocationLoader />}
        {isLoad && accessToken ? InfiniteList() : UnAuthList()}
        <div className={styles.keywordSelectorWrapper}>
          <div className={styles.keywordSelector}>
            <button
              className={`${styles.keyword} ${styles.keyword_left} ${
                selectedCategory === '전체' ? styles.selected : ''
              }`}
              onClick={() => toggleSelectedCategory('전체')}>
              전체
            </button>
            <button
              className={`${styles.keyword} ${
                selectedCategory === 'Restaurant' ? styles.selected : ''
              }`}
              onClick={() => toggleSelectedCategory('Restaurant')}>
              음식점
            </button>
            <button
              className={`${styles.keyword} ${
                selectedCategory === 'Cafe' ? styles.selected : ''
              }`}
              onClick={() => toggleSelectedCategory('Cafe')}>
              카페
            </button>
            <button
              className={`${styles.keyword} ${styles.keyword_right} ${
                selectedCategory === 'Bar / Pub' ? styles.selected : ''
              }`}
              onClick={() => toggleSelectedCategory('Bar / Pub')}>
              바/펍
            </button>
          </div>
        </div>
        {<Layout.BottomNavigation />}
        <AddressSelector
          addressList={addressList}
          updateAddress={updateAddress}
          setIsGeolocationModalOpen={setIsGeolocationModalOpen}
          setIsLoad={setIsLoad}
          callback={() => {
            resetScroll();
            window.scrollTo(0, 0);
          }}
        />
        <BrandSortBottomSheet
          isOpen={isOpenSortModal}
          setIsOpen={setIsOpenSortModal}
          currentSort={args.sort}
          toggle={(sort: 'famous' | 'newest') => {
            console.log(sort);
            setIsOpenSortModal(false);
          }}
        />
        <GeolocationModal
          isOpen={isGeolocationModalOpen as boolean}
          setIsOpen={setIsGeolocationModalOpen as (is: boolean) => void}
          action={async () => {
            setIsGeolocationModalOpen && setIsGeolocationModalOpen(false);
            await Echo.echo({
              value: 'geolocation',
            });
          }}
        />
        <BrandLoginModal
          isOpen={isOpenModal}
          setIsOpen={(is: boolean) => setIsOpenModal(is)}
          action={() => navigator('/login')}
        />
      </div>
    </Layout.Main>
  );
}
